.actionPlanStandardSelection {
  .options {
    display: flex;

    .option {
      flex: 1;
      margin: 0 0.2rem;
      padding: 0.6rem 0.8rem 0.6rem 0.2rem;
      background: #fafafa;
      border: 1px solid #f2f2f2;
      cursor: pointer;
      overflow: hidden;
      text-align: left;

      strong {
        padding: 0 0.8rem;
      }

      .optionBody {
        display: flex;
        align-items: center;

        .score {
          flex: 0 0 100px;
          text-align: center;
          margin: -0.8rem 0 -0.2rem;
          font-size: 4rem;
          opacity: 0.9;
          font-weight: 600;
        }

        .description {
          flex: 1;
        }
      }

      &.selected {
        background: #dff0d8;
        color: #283e1f;
      }
      p {
        margin: 0;
      }
    }
  }
}

.react-datepicker-wrapper {
  display: block;
}