.actionPlanInitialPage {
  margin-top: -1rem;
  position: relative;
  .savingSpinner {
    font-size: 1.2rem;
    z-index: 1000;
    .savingSpinnerInner {
      position: absolute;
      left: 0px;
      top: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      background: rgba(245, 245, 245, 0.75);
      z-index: 1000;
    }
  }
}

.actionPlanInitialForm {
  padding-bottom: 3rem;
}

// Action plan progress bottom bar
.actionPlanProgress {
  background: rgba(245, 245, 245, 0.85);
  backdrop-filter: blur(2px);
  position: fixed;
  bottom: 0px;
  width: 100%;
  left: 0px;
  .actionPlanProgressInner {
    padding: 0.8rem 0 0;
    
    position: relative;
    max-width: 1100px;
    margin: 0 auto;
    .count {
      position: absolute;
      right: 0px;
      top: 8px;
      color: #666;
    }
  }
  .actionPlanProgressButtons {
    display: flex;
    padding: 0.4rem 1rem;
    position: relative;
    .key {
      flex: 1;
      display: flex;

      > div {
        display: flex;
        align-items: center;
        &::before {
          content: '';
          width: 12px;
          height: 12px;
          margin-right: 6px;
          display: block;
        }
        flex: 1;
        
        &.complete::before {
          background-color: #109b71;
        }
        &.incomplete::before {
          background-color: #e99000;
        }
        &.unviewed::before {
          background-color: #ddd;
        }
      }


    }
    .previous {
      flex: 0 0 340px;
    }
    .next {
      flex: 0 0 340px;
      text-align: right;
    }
  }
  h4 {
    margin: 0;
    padding: 0.5rem 1rem 0.6rem;
    text-align: center;
  }
  .progressView {
    padding: 3.2rem 4rem 0;
    display: flex;
    .topic {
      position: relative;
      flex: 1;
      height: 24px;
      transition: height 0.2s, margin-top 0.2s;
      cursor: pointer;

      &.current {
        height: 50px;
        margin-top: -26px;
        pointer-events: none;

        .topicName {
          display: block;
        }
      }

      &:not(.current) {
        &:hover {
          height: 34px;
          margin-top: -10px;
          -webkit-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.2);
          -moz-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.2);
          box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.2);
          .topicName {
            display: block;
          }
        }
      }

      &.incomplete {
        background: #e99000;
      }
      &.complete {
        background: #109b71;
      }
      &.unviewed {
        background: #ddd;
      }

      .topicName {
        display: none;
        position: absolute;
        top: -22px;
        margin: 0 auto;
        width: 280px;
        text-align: center;
        left: calc(50% - 140px);
        z-index: 20;
      }
    }
  }
}