$checklist-color-danger: #a70b10;
$checklist-color-warning: #E99002;
$checklist-color-success: #009b72;
$checklist-color-info: #029ce2;

.checklistActions {
  .checklistActionsTable {
  .topic {
      width: 43%;
    }
    .assignedTo {
      width: 12%;
    }
    .deadline {
      width: 10%;
    }
    .currentProgress {
      width: 7%;
    }
    .document {
      width: 9%;
    }
    .actions {
      width: 20%;
      text-align: right;
    }
  }
  .checklistCategory {
    margin-bottom: 2.4rem;
    &:last-of-type {
      margin-bottom: 0;
      border-bottom: none;
    }
    h2 {
      margin: 0.2rem 0 0.8rem;
    }
    .checklistItemDetails {
      background: #fafafa;
      border-bottom: 2px solid #ddd;
      margin-bottom: 0.8rem;

      &:last-of-type {
        margin-bottom: 0;
      }

      &.checklist-color-success {
        border-left: 6px solid $checklist-color-success;
      }

      &.checklist-color-warning {
        border-left: 6px solid $checklist-color-warning;
      }

      &.checklist-color-danger {
        border-left: 6px solid $checklist-color-danger;
      }

      &.checklist-color-info {
        border-left: 6px solid $checklist-color-info;
      }
    }
    .checklistItemHeader {
      position: relative;
      padding-bottom: 0.4rem;

      .item {
        font-size: 1.2rem;
        padding: 0.6rem 0.6rem 0;
      }
      .standard {
        position: relative;
        font-size: 0.8rem;
        padding: 0 0.6rem 0.6rem;
        color: #555;
      }

      button {
        position: absolute;
        bottom: 8px;
        right: 8px;
      }
    }
  }
}