.actionPlanIndexScreen {
  .category, .feedback, .actionPlanIndexFooter {
    background: #f3f3f3;
    padding: 0.8rem;
    margin-bottom: 0.8rem;
    h1 {
      font-size: 1.6rem;
    }
  }
  .topic {
    .status {
      i {
        margin-right: 0.4rem;
      }
      &.complete {
        i {
          color: #009b72;
        }
      }
      &.actionsDueSoon {
        i {
          color:  #E99002;
        }
      }
      &.actionRequired, &.actionsOverdue {
        i {
          color: #a70b10;
        }
      }
    }
  }

  .topicsTable {
    .topic {
      width: 30%;
    }
    .on-site {
      width: 11%;
    }
    .targetScore {
      width: 11%;
    }
    .currentProgress {
      width: 11%;
    }
    .actionsStatus {
      width: 22%;
    }
    .actions {
      width: 15%;
    }
  }

  .customActionsTable {
    .actions {
      width: 22%;
    }
  }

  .feedback {
    max-height: 52px;
    transition: max-height 0.5s;
    overflow: hidden;

    .header {
      display: flex;
      justify-content: space-between;
      cursor: pointer;

      i {
        font-size: 1.6rem;
        color: #682181;
      }
    }

    &.open {
      max-height: 2000px;
    }
  }

  .category {
    .header {
      display: grid;
      grid-template-columns: 1fr 140px 60px 140px 60px;
      border-bottom: 1px solid #d8d8d8;
      margin-bottom: 0.8rem;
  
      h1 {
        flex: 1;
      }
      .scoreTitle, .scoreValue {
        font-size: 1rem;
        text-align: right;
      }
      .scoreValue {
        span {
          color: #fff;
          padding: 0.2rem 0.4rem;
          &.score-red {
            background: #a70b10;
          }
          &.score-dark-green {
            background: #029ce2;
          }
          &.score-green {
            background: #009b72;
          }
          &.score-amber {
            background: #E99002;
          }
        }
      }
    }
  }

  .actionPlanOverviewTabs {
    margin-top: 1.2rem;
    margin-bottom: 0.6rem;

    ul {
      margin: 0;
      padding: 0;
      padding-left: 0.5rem;
      list-style: none;
      border-bottom: 2px solid #682181;
      li {
        display: inline-block;
        button {
          margin-bottom: -2px;
          margin-right: 0.5rem;
          border: 2px solid #682181;
          font-size: 1.1rem;
          padding: 0.4rem 1rem;
          color: #682181;
          background: transparent;
          background: linear-gradient(180deg, rgba(255,255,255,0) 65%, #68218111 100%);

          &:hover, &:focus {
            background: #68218122;
            outline: none;
          }

          &.selected {
            border-bottom: 2px solid #fff;
            background: transparent;
          }
        }
      }
    }
  }
}

.actionPlanActionsForm {
  .actions {
    .action {
      margin-bottom: 0.8rem;
      padding-bottom: 0.8rem;
      border-bottom: 1px solid #ccc;
      display: -ms-grid;
      display: grid;
      -ms-grid-columns: 1fr 1em 1fr;
      grid-template-columns: repeat(auto-fill, minmax(540px, 1fr));
      grid-gap: 1.4rem;

      .inputs {
        -ms-grid-column: 1;
      }

      .details {
        -ms-grid-column: 3;
      }
    }
  }
  .details {
    display: flex;
    flex-direction: column;
    .actionDetails {
      flex: 1;
      .description {
        margin-top: 1.2rem;
      }
    }
    .actionActions {
      flex: 0 0 36px;
      text-align: right;
    }
  }
}

.actionDetails {
  padding-bottom: 2rem;
  .description {
    font-size: 1.2rem;
    margin-top: 0.2rem;
    margin-bottom: 0.4rem;
  }
}

.iconDueSoon {
  color: #E99002;
}

@mixin timelineLine {
  content: '';
  display: block;
  position: absolute;
  left: 0px;
  right: 0px;
  margin: 0 auto;
  width: 6px;
  height: 50%;
  border: 3px solid #009b72;
  background-color: #009b72;
}

@mixin timelineMarker {
  content: '';
  position: absolute;
  top: calc(50% - 12px);
  left: 0px;
  right: 0px;
  margin: 0 auto;
  width: 24px;
  height: 24px;
  background: #fff;
  border: 5px solid #009b72;
  border-radius: 12px;
}

.actionForm {
  .editButton {
    position: absolute;
    top: -16px;
    right: 0px;
  }
}

.actionProgressHistory {
  .timeline {
    padding-top: 1.4rem;
    .timelineStart, .timelineEntry {
      display: -ms-grid;
      display: grid;
      -ms-grid-columns: 64px 1fr;
      grid-template-columns: 64px 1fr;
    }

    .timelineStart {
      .timelineLine {
        position: relative;
        -ms-grid-column: 1;
        grid-column: 1;
        &:before {
          @include timelineLine;
          height: 100%;
        }
        &:after {
          content: '';
          position: absolute;
          top: calc(50% - 12px);
          left: 0px;
          right: 0px;
          top: 0px;
          margin: 0 auto;
          width: 24px;
          height: 6px;
          border: 3px solid #009b72;
          background-color: #009b72;
        }
      }
      .details {
        -ms-grid-column: 2;
        grid-column: 2;
        font-size: 1.2rem;
        padding: 0.8rem 0.2rem;
        margin-top: -1.4rem;
      }
    }

    .timelineEntry {
      .timelineGraphic {
        position: relative;
        -ms-grid-column: 1;
        grid-column: 1;
        .timelineLine {
          &:before {
            @include timelineLine;
            top: 0px;
          }
          &:after {
            @include timelineLine;
            bottom: 0px;
          }
        }
        .timelineMarker {
          @include timelineMarker;
        }
      }

      &.latest {
        .timelineLine {
          &:after {
            display: none;
          }
        }
      }
        
      .detailsWrap {
        padding: 0.8rem 0;
        -ms-grid-column: 2;
        grid-column: 2;
        .details {
          background: #f2f2f2;
          color: #333;
          padding: 0.8rem 0.8rem 1.6rem;
          position: relative;
          .detailsHeader {
            display: grid;
            grid-template-columns: 1fr 1fr;
            .percentage {
            }
            .time {
              text-align: right;
            }
          }
          .description {
            font-size: 1.05rem;
            padding: 0.4rem 0;
          }
          .document {
            position: absolute;
            bottom: 0.8rem;
            right: 0.8rem;
          }
        }
      }
    }
  }
}

.supportingEvidence {
  .document {
    display: flex;
    align-items: center;
    padding: 0.4rem 0.4rem 0.4rem 0.8rem;
    border-left: 4px solid #682181;
    background-color: #f2f2f2;
    margin-bottom: 0.6rem;

    .documentName {
      flex: 1;
      p {
        margin: 0;
      }
    }
    .documentActions {
      flex: 0 0 92px;
    }
  }

  .documentInput {
    width: 100%;
    position: relative;

    label {
      width: 100%;
      padding: 0.8rem 1rem;
      background-color: #f2f2f2;
      border: 4px solid #e4e4e4;
      border-style: dashed;
      font-size: 1.1rem;
      font-weight: 600;
      cursor: pointer;

      small {
        margin-left: 1rem;
      }
    }
    input {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }
}